import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'confirmation-dialog',
  standalone: true,
  imports: [MatDialogModule, MatIconModule],
  templateUrl: './confirmation-dialog.component.html',
  styleUrl: './confirmation-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmationDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public changeDetectorRef: ChangeDetectorRef
  ) { 
  }

  onCloseClicked(): void {
    this.dialogRef.close();
  }
}

export interface DialogData {
  title: string;
  message: string;
  mainButtonText: string;
  altButtonText: string;
  showAltButton: boolean;
  showIcon: boolean;
}
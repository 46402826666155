<mat-dialog-content class="dialog">
    <div class="contentContainer">
        @if (data.showIcon) {
        <div class="image">
            <img src="assets/warning-icon.svg" alt="" class="icon" />
        </div>
        }
        <div class="content">
            <div class="title">{{data.title}}</div>
            <div class="message" [innerHtml]="data.message"></div>            
        </div>
    </div>
    
    <div class="actionContainer">
        <button mat-button [mat-dialog-close]="true" cdkFocusInitial class="mainButton">{{data.mainButtonText}}</button>
        @if(data.showAltButton) {
            <div class="altContainer">
                <button mat-button [mat-dialog-close]="false" class="altButton">{{data.altButtonText}}</button>
            </div>            
        }
    </div>
</mat-dialog-content>